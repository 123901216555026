.form input {
	box-shadow: none;
	font-size: 1em;
	padding: 1.1em 2em;
	border: none;
	border-bottom: 0.1em solid white;
	color: white;
	border-radius: 0;
	background: transparent;
}

.form input::placeholder {
	color: white;
}

.form input:focus {
	outline: none;
	border: none;
	border-bottom: 0.2em dashed white;
}

.form input:autofill {
	background-color: transparent !important;
	background-image: none !important;
	border-color: blue;
}

.form a {
	color: white;
}