.center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.spacing > * {
	margin: 8px 0;
}

.stretch {
	align-items: stretch;
}
