.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-position: center;
	position: relative;
	justify-content: center;
}

.mainIcon {
	position: absolute;
	margin: auto;
	width: 100vw;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}

.mainIcon > span {
	margin: 0;
	margin-left: 10px;
	font-size: 2em;
}

.mainIcon > svg {
	color: var(--primary);
	background-color: white;
	border-radius: 0.5em;
	padding: 5px 20px;
	width: 2em;
}

.background {
	box-shadow: 0px 0px 6px 0px #192e04;
	overflow: hidden;
	background: var(--background-color);
	border-radius: 1em 0 0 1em;
	top: calc(var(--navbar-height) + 20px);
	left: 20px;
	width: calc(100% - 20px);
	height: calc(100% - var(--navbar-height) - 40px);
	position: fixed;
	z-index: -1;
}

.background > svg[name="top-left"] {
	transform: rotate(30deg);
	color: var(--background-accent);
	position: absolute;
	top: -4em;
	right: -4em;
	height: 15em;
}

.background > svg[name="bottom-right"] {
	transform: rotate(30deg);
	color: var(--background-accent);
	position: absolute;
	bottom: 4em;
	right: -4em;
	height: 10em;
}

.background > svg[name="bottom-left"] {
	transform: rotate(20deg);
	color: var(--background-accent);
	position: absolute;
	bottom: -4em;
	left: -4em;
	height: 15em;
}

.background > svg[name="center-1"] {
	transform: rotate(20deg);
	color: var(--background-accent);
	position: absolute;
	bottom: 8em;
	right: 20em;
	height: 25em;
}

.background > svg[name="center-2"] {
	transform: rotate(20deg);
	color: var(--background-accent);
	position: absolute;
	top: -3em;
	left: -4em;
	height: 20em;
}

.background > svg[name="center-3"] {
	transform: rotate(20deg);
	color: var(--background-accent);
	position: absolute;
	top: 13em;
	left: 24em;
	height: 15em;
}

.navbar {
	color: white;
	background-color: var(--primary);
	display: flex;
	align-content: center;
	justify-content: space-between;
	align-items: center;
	height: var(--navbar-height);
	box-shadow: 0px -2px 10px grey;
	padding: 0 3em;
}

.navbar > nav > div {
	display: flex;
	align-items: center;
}

.navbar > nav > div > a {
	padding: 1em;
}

.username {
	margin: 0 1em;
}

.main {
	height: calc(100vh - var(--navbar-height) - 40px);
	padding: 20px 0 20px 20px;
}

.logoutButton {
	display: block;
}
.menuButton {
	display: none;
}

.warning {
	display: none;
}

@media (max-width: 700px) {
	.mainIcon {
		position: unset;
		margin: unset;
	}

	.iconText {
		display: none;
	}

	.main {
		height: calc(100vh - var(--navbar-height));
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.background {
		width: 100%;
		height: calc(100% - var(--navbar-height));
		box-shadow: none;
		top: var(--navbar-height);
		left: 0;
		border-radius: 0;
	}
}
