.mainMenu {
	display: flex;
	flex-direction: column;
	border-radius: 1em 0 0 1em;
	padding: 15px 20px;
	background-color: #86868642;
	border-left: none;
	border-bottom: none;
	box-shadow: 4px 0 2px -2px #00000030;
	overflow-x: auto;
}

.logoutButton {
	display: none;
}

.disabledLink,
.disabledLink button {
	pointer-events: none;
	color: gray;
}

.mainMenu button {
	margin-bottom: 1em;
	font-size: 1.5em;
}

.container {
	display: flex;
	height: inherit;
	position: relative;
}

.context {
	padding: 25px;
	background-color: #6d6d6d75;
	width: 100%;
	position: relative;
	overflow: auto;
}

@media (max-width: 700px) {
	.mainMenu {
		border-radius: 0;
		position: absolute;
		z-index: 1;
		width: 100%;
		box-sizing: border-box;
		background: #a5a5a57a;
		backdrop-filter: blur(8px);
		overflow: auto;
		height: 100%;
	}

	.context {
		padding: 4px;
	}
}
