.container {
	--border-radius: 4px;
	--header-height: 4rem;

	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	border-radius: var(--border-radius);
	color: white;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.header {
	cursor: move;
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: var(--border-radius);
	backdrop-filter: blur(8px);
	background-color: var(--primary);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.486);
	justify-content: space-around;
	z-index: 1;
	height: var(--header-height);
}

.header > * {
	margin: 8px;
}

.form {
	box-shadow: 0px 1px 5px black;
	position: relative;
	border-radius: var(--border-radius);
	width: 100%;
	display: flex;
	flex-direction: column;
	height: calc(100% - var(--header-height));
}

.form::before {
	z-index: -1;
	top: 0;
	content: "";
	position: absolute;
	border-radius: var(--border-radius);
	background-color: #00000030;
	backdrop-filter: blur(8px);
	height: 100%;
	width: 100%;
}

.mainContainer {
	flex: 1;
	overflow-y: auto;
	padding: 8px;
}

.form footer {
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.486);
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form footer > * {
	margin: 8px;
}

.mainContainer fieldset {
	border: none;
}

.mainContainer fieldset input,
.select {
	box-sizing: border-box;
	padding: 1.1em 2em;
	border-radius: 1em;
	width: 100%;
	font-size: 1em;
}

.mainContainer fieldset legend {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 2px 4px;
	color: black;
	margin: 4px;
}

.productContainer .productListItem label {
	margin-right: 10px;
}

.productContainer .productListItem {
	background-color: white;
	color: black;
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 4px;
	margin: 4px;
	position: relative;
}

.productContainer .productListItem span {
	padding-left: 1px;
	height: 100%;
	top: 0;
	margin-left: -4px;
	position: absolute;
	background: black;
}

.productContainer .productListValues {
	margin: 4px;
	display: flex;
	justify-content: space-evenly;
}

.productContainer .productListValues button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3rem;
	height: 3rem;
	border: 1px solid black;
	border-radius: 4px;
	background: transparent;
}

.productContainer .productItemAmount {
	font-size: 2rem;
}

.deviceSVG {
	width: 40vw;
	min-width: 500px;
}

.deviceSVG svg {
	border-radius: 1em;
}

.deviceSVG text {
	pointer-events: none;
	font-family: unset;
}