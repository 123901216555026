.container {
	display: flex;
	justify-content: center;
	height: calc(100% - 16em);
}

.form {
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 700px;
	margin: auto;
}

.form > * {
	margin-top: 1.5em;
}

@media (max-width: 700px) {
	.container {
		display: block;
	}
	.form {
		margin-top: 3em;
	}
}

.flexCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.checkbox label {
	margin-right: 10px;
}

.checkbox {
	background-color: white;
	width: fit-content;
	color: black;
	background-color: white;
	border: 1px solid black !important;
	border-radius: 4px;
	padding: 5px !important;
	position: relative;
}

.checkbox input {
	box-shadow: none;
}

.checkbox span {
	padding-left: 1px;
	height: 100%;
	top: 0;
	margin-left: -4px;
	position: absolute;
	background: black;
}

.checkbox a {
	color: unset !important;
}

.signUpContainer {
	display: flex;
	margin-top: var(--navbar-height);
	height: calc(100vh - var(--navbar-height));
	overflow: auto;
}
