.container {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 4px;
	max-width: 700px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 1px 5px black;
}

.header {
	color: white;
	cursor: move;
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: 4px;
	backdrop-filter: blur(8px);
	background-color: var(--primary);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.486);
	justify-content: space-around;
}

.form {
	padding-top: 20px;
	height: 100%;
	width: calc(100% - 2em);
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	justify-content: space-between;
}

.form::before {
	top: 0;
	content: "";
	position: absolute;
	background-color: #00000030;
	backdrop-filter: blur(8px);
	height: 100%;
	width: calc(100% + 2em);
}

.form fieldset {
	border: none;
	padding: 0;
	position: relative;
}

.form fieldset legend {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 2px 4px;
	position: absolute;
	color: black;
	top: -10px;
	left: 15px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

.buttons > button {
	margin-left: 15px;
}

.form input[type="text"] {
	width: calc(100% - 4.2em);
}

.form > * {
	width: 100%;
	margin: 15px 0;
}

.mapContainer {
	min-height: 300px;
	height: 40vh;
}
