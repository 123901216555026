.container {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 4px;
	max-width: 500px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	box-shadow: 0px 1px 5px black;
}

.header {
	cursor: move;
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: 4px;
	backdrop-filter: blur(8px);
	background-color: var(--primary);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.486);
	justify-content: space-around;
}

.form {
	padding-top: 20px;
	height: 100%;
	width: calc(100% - 2em);
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	justify-content: space-between;
}

.form::before {
	z-index: -1;
	top: 0;
	content: "";
	position: absolute;
	background-color: #00000030;
	backdrop-filter: blur(8px);
	height: 100%;
	width: calc(100% + 2em);
}

.form fieldset {
	border: none;
	padding: 0;
	position: relative;
}

.form fieldset legend {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 2px 4px;
	position: absolute;
	color: black;
	top: -10px;
	left: 15px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

.buttons > button {
	margin-left: 15px;
}

.form input[type="text"] {
	width: calc(100% - 4.2em);
}

.form > * {
	width: 100%;
	margin: 15px 0;
}

.form .checkbox label {
	margin-right: 10px;
}

.form .checkbox {
	background-color: white;
	width: fit-content;
	color: black;
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 5px;
	position: relative;
}

.form .checkbox span {
	padding-left: 1px;
	height: 100%;
	top: 0;
	margin-left: -4px;
	position: absolute;
	background: black;
}

.checkboxContainer {
	display: flex;
	justify-content: space-evenly;
}

.checkboxContainer > div > fieldset {
	margin-bottom: 15px;
}

.select {
	padding: 1.1em 2em;
	border-radius: 1em;
	width: 100%;
	font-size: 1em;
}