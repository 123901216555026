.main {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
}

.header {
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.header > div > button {
	margin-left: 15px;
}

table.table td[name="edit"]:hover,
table.table .icon button[name="active"] {
	background-color: #33ff004d;
}

table.table .icon {
	height: 0;
	padding: 0;
	text-align: center;
	position: relative;
}

table.table .icon button {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
}

table.table tbody tr:hover {
	background-color: lightblue !important;
}

table.table tbody tr button:hover {
	cursor: pointer;
}

.panelContainer {
	border-radius: 1em;
	margin-bottom: 2em;
	padding: 0 1em;
	padding-bottom: 1em;
	backdrop-filter: blur(8px);
	background-color: #00000030;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	min-width: calc(100% - 2em);
}
