.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	height: 50%;
	justify-content: space-evenly;
}

.container h2 {
	text-align: center;
}
