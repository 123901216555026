.main {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
}

.header {
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.header > * {
	margin: 8px;
}

table.table td[name="edit"]:hover,
table.table .icon button[name="active"] {
	background-color: #33ff004d;
}

table.table .icon {
	height: 0;
	padding: 0;
	text-align: center;
	position: relative;
}

table.table .icon button {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
}

table.table tbody tr:hover {
	background-color: lightblue !important;
}

table.table tbody tr button:hover {
	cursor: pointer;
}

table.table {
	margin: unset;
}

.panelContainer {
	border-radius: 1em;
	margin: auto;
	margin-bottom: 2em;
	padding: 1em 1.2em;
	backdrop-filter: blur(8px);
	background-color: #00000030;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.select {
	padding: 1.1em 2em;
	border-radius: 1em;
	font-size: 1em;
	margin-bottom: 15px;
}

@media (max-width: 1200px) {
	.offersHeaderContainer {
		flex-direction: column;
		align-items: flex-start !important;
	}
}

.deviceImage svg {
	border-radius: 1em;
}

.deviceImage text {
	pointer-events: none;
	font-family: unset;
}

.offersContainer {
	align-self: center;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 8px;
}

.offersHeaderContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.offersHeaderContainer > * {
	margin: 8px;
}

.buttonsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.buttonsContainer > * {
	margin: 4px;
}

.deviceContainer {
	display: block;
	box-sizing: border-box;
	overflow: auto;
	max-width: 100%;
}
