.container {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 4px;
	max-width: 90vw;
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	box-shadow: 0px 1px 5px black;
}

.header {
	cursor: move;
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: 4px;
	backdrop-filter: blur(8px);
	background-color: var(--primary);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.486);
	justify-content: space-around;
}

.main {
	padding-top: 20px;
	height: 100%;
	width: calc(100% - 2em);
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	justify-content: space-between;
	color: black;
}

.main::before {
	z-index: -1;
	top: 0;
	content: "";
	position: absolute;
	background-color: #00000030;
	backdrop-filter: blur(8px);
	height: 100%;
	width: calc(100% + 2em);
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

.buttons > button {
	margin-left: 15px;
}

.main > * {
	width: 100%;
	margin: 15px 0;
}

.mainContainer {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.tableControls {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tableControls > * {
	margin-bottom: 10px;
}

.tableContainer {
	display: flex;
	width: calc(90vw - 300px);
	height: calc(80vh - 128px);
	overflow: auto;
}

.table div {
	display: grid;
	width: 100px;
	min-height: 60px;
}
