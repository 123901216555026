table.table {
	border-spacing: 0;
	margin: auto;
	border-radius: 8px;
	border: 1px solid black;
	background-color: white;
	position: relative;
}

table thead {
	background-color: white;
	position: sticky;
	z-index: 1;
	top: 0;
}

table thead {
	box-shadow: 0px -4px 8px 1px #7b7b7b;
}

table.table tr:first-child th:first-child {
	border-top-left-radius: 8px;
}

table.table tr:first-child th:last-child {
	border-top-right-radius: 8px;
}

table.table tr:last-child td:first-child {
	border-bottom-left-radius: 8px;
}

table.table tr:last-child td:last-child {
	border-bottom-right-radius: 8px;
}

table.table th:first-child,
table.table th:not(:first-child):not(:last-child),
table.table td:first-child,
table.table td:not(:first-child):not(:last-child) {
	border-right: 1px solid black;
}

table.table tr:not(:last-child) th,
table.table tr:first-child td,
table.table tr:not(:last-child) td {
	border-bottom: 1px solid black;
}

table.table tr:first-child th {
	border-bottom: 2px solid black;
}

table.table .additionalRow {
	border-right: unset !important;
	border-bottom: 2px solid black !important;
}

table.table tbody.hasHiddenColumns tr:nth-child(4n-3),
table.table tbody.singleRows tr:nth-child(2n + 1) {
	background: #e4e4e4;
}

table.table tbody.hasHiddenColumns tr:nth-child(4n-2) {
	background: #e2e2e2;
	box-shadow: inset 0px -2px 2px 0px gray;
}

table.table tbody.hasHiddenColumns tr:nth-child(4n-2):hover {
	background-color: #e7e7e7 !important;
}

table.table td,
table.table th {
	padding: 0.5rem;
	text-align: left;
}

table.table .icon {
	text-align: center;
}

table.table td[name="action"]:hover,
table.table .icon button[name="active"] {
	background-color: #33ff004d;
}

table.table .icon {
	height: 0;
	padding: 0;
	text-align: center;
	position: relative;
}

table.table .icon button {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
}

table.table tbody tr:hover {
	background-color: lightblue !important;
}

table.table tbody tr button:hover {
	cursor: pointer;
}

table.table .headCell {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
}

table.table .headTextCell {
	margin-top: 4px;
	white-space: nowrap;
}

table.table .headCellButtons {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
}

table.table .headCellContainer {
	position: relative;
}

table.table .headCellButton {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.5s;
	margin: 2px;
}

table.table .headCellButtonUse {
	color: #c54800;
}

table.table .headCellButton:hover,
table.table .headCellButtonActive[name="filter"] {
	color: var(--primary);
}

.filterDialog {
	position: absolute;
	background: white;
	border: 1px solid black;
	border-radius: 1em;
	padding: 1em;
	top: 100%;
	left: 0;
}

.dateTimeContainer {
	display: flex;
	position: absolute;
	align-items: center;
	background: white;
	box-shadow: 0px 1px 5px black;
	border-radius: 1em;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
}

.dateTimeContainer .filterDialog {
	position: relative;
	width: 5em;
}
.dateTimeContainer span {
	padding: 0 1em;
}

.dateTimeContainer button {
	background: white;
	border: none;
	transition: 1s;
}

.dateTimeContainer button:hover {
	color: var(--primary);
}

.dateTimeContainer button:active {
	color: var(--success-color);
}

.select {
	padding: 0.7em 1em;
	border-radius: 1em;
	width: 8em;
	font-size: 1em;
}

.fieldset {
	border: none;
	padding: 0;
	position: relative;
}

.fieldset legend {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 2px 4px;
	color: black;
	margin: auto;
	margin-bottom: 4px;
}

.controlsContainer {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}

.controlsContainer > * {
	margin: 8px;
}

.rowInfo {
	display: flex;
	align-items: center;
}

.selectRowCheckbox:hover {
	background-color: #bde6ad !important;
}

.selectRowCheckbox .checkboxHover {
	display: none;
}

.selectRowCheckbox:hover .checkboxHover {
	display: block;
}

.selectRowCheckbox > div {
	display: flex;
	justify-content: center;
	align-items: center;
}