.container {
	background-color: white;
	padding: 2px 10px;
	padding-right: 0;
	border-radius: 4px;
	border: 1px solid black;
	display: flex;
	align-items: center;
	position: relative;
}

.divider {
	padding-left: 1px;
	height: 100%;
	top: 0;
	position: absolute;
	background: black;
}

.buttons {
	height: 100%;
}

.buttons > button {
	border: none;
	height: 44px;
	width: 48px;
	background: white;
	font-size: 20px;
	margin: 0;
}

.buttons > button:hover {
	background: #e3ffc2;
}

.number {
	text-align: center;
	width: 48px;
}

.title {
	padding-right: 10px;
	margin: auto;
}
