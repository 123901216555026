.main {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
}

.header {
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.header > .buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	gap: 0.5em;
}

.panelContainer > h2 {
	color: white;
}

.panelContainer {
	border-radius: 1em;
	margin: auto;
	margin-bottom: 2em;
	padding: 1em 1.2em;
	backdrop-filter: blur(8px);
	background-color: #00000030;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	gap: 1em;
}

table.table tbody tr td {
	width: 250px;
	height: 100px;
}

table.table tbody tr td > div {
	display: grid;
	user-select: none;
}

table.table tbody tr td > div > span:first-child {
	width: 100%;
	text-align: center;
}

table.table tbody tr td > div > span:nth-child(2) {
	margin-top: 10px;
}

.spaceAround {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	gap: 1em;
}

.infoContainer {
	border: 1px solid;
	border-radius: 4px;
	background: white;
	display: flex;
	flex-direction: column;
	margin: 15px 0;
}

.infoContainer > span:not(:last-child) {
	border-bottom: 1px solid;
}

.infoContainer > span {
	padding: 5px 10px;
	font-size: 1.2em;
}

.deviceSettingsContainer {
	margin-top: 0.83em;
}

.deviceSettingsContainer > table.hover tbody tr td:hover {
	background-color: lightblue;
}

.tmpHover:hover {
	background-color: lightblue;
}

.deviceSettingsContainer > table tbody tr td > div {
	display: grid;
	user-select: none;
}

.deviceSVG {
	height: 100%;
	position: sticky;
	top: 100px;
	z-index: 1;
	margin-left: 15px;
}

.deviceSVG svg {
	border-radius: 1em;
}

.deviceSVG text {
	pointer-events: none;
	font-family: unset;
}

.dateTimePicker {
	background: white;
	box-shadow: 0px 1px 5px black;
	border-radius: 1em;
}

.searchDateContainer {
	display: flex;
	/* background: gray; */
	/* border: 1px solid red; */
	align-items: self-end;
	justify-content: space-between;
	margin-bottom: 8px;
}

.activeCurrentStatus {
	background-color: var(--warning-color);
}

.information {
	padding: 8px;
	background: white;
	box-shadow: 0px 1px 5px black;
	border-radius: 1em;
}

@media (max-width: 700px) {
	.spaceAround {
		flex-direction: column;
		align-items: center;
	}
}
