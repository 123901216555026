.container {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 4px;
	max-width: 400px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 1px 5px black;
}

.header {
	color: white;
	cursor: move;
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: 4px;
	backdrop-filter: blur(8px);
	background-color: var(--primary);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.486);
	justify-content: space-around;
}

.form {
	padding-top: 20px;
	height: 100%;
	width: calc(100% - 2em);
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
}

.form::before {
	top: 0;
	content: "";
	position: absolute;
	background-color: #00000030;
	backdrop-filter: blur(8px);
	height: 100%;
	width: calc(100% + 2em);
	z-index: -1;
}

.form > * {
	width: 100%;
	margin: 15px 0;
}

.qrCodeContainer {
	display: flex;
	justify-content: center;
}

.qrCodeContainer > * {
	background: #fff;
	border-radius: 1em;
	padding: 20px;
}
