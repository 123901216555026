.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-position: center;
	position: relative;
	justify-content: center;
}

.background {
	background: var(--background-color);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -1;
}

.background > svg[name="top-left"] {
	transform: rotate(30deg);
	color: var(--background-accent);
	position: absolute;
	top: -4em;
	right: -4em;
	height: 15em;
}

.background > svg[name="bottom-right"] {
	transform: rotate(30deg);
	color: var(--background-accent);
	position: absolute;
	bottom: 4em;
	right: -4em;
	height: 10em;
}

.background > svg[name="bottom-left"] {
	transform: rotate(20deg);
	color: var(--background-accent);
	position: absolute;
	bottom: -4em;
	left: -4em;
	height: 15em;
}

.foodBoxLogoContainer {
	margin-left: 10%;
	display: flex;
	justify-content: space-between;
	align-items: start;
	cursor: pointer;
}
.foodBoxLogoContainer span {
	margin: 0;
	margin-left: 5px;
	font-size: 2.5em;
	font-weight: 500;
}
.titleContainer {
	height: var(--navbar-height);
	transition: margin 1s;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
	user-select: none;
}

.foodBoxLogo {
	filter: invert(0.7);
}
.navbarIcons {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: center;
	padding-right: 2em;
}
.startpage {
	position: relative;
	justify-content: center;
	height: 5em;
	margin-bottom: 3em;
}
.startpage .foodBoxLogo {
	filter: none;
}
.startpage .foodBoxLogoContainer {
	flex-direction: column;
	color: #fff;
	margin: 0;
}
.startpage .foodBoxLogoContainer span {
	color: #fff;
}
/* 
@media (max-width: 1400px) {
	.container {
		font-size: 0.8vw;
	}
} */
.hiddenNavbar > .foodBoxLogoContainer > span {
	color: #fff;
}

.hiddenNavbar.titleContainer {
	justify-content: center;
	align-items: center;
}

.hiddenNavbar .foodBoxLogo {
	filter: none;
}

.hiddenNavbar .foodBoxLogoContainer {
	margin: 0;
}
