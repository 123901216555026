.main {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
}

.header {
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.headerButtons {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.headerButtons > * {
	margin: 4px;
}

.panelContainer > h2 {
	color: white;
}

.panelContainer {
	border-radius: 1em;
	margin: auto;
	margin-bottom: 2em;
	padding: 1em 1.2em;
	backdrop-filter: blur(8px);
	background-color: #00000030;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

table.table tbody tr td {
	width: 250px;
	height: 100px;
}

table.table tbody tr td > div {
	display: grid;
	user-select: none;
}

table.table tbody tr td > div > span:first-child {
	width: 100%;
	text-align: center;
}

table.table tbody tr td > div > span:nth-child(2) {
	margin-top: 10px;
}

.spaceAround {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	width: 100%;
	flex-wrap: wrap;
}

.spaceAround > * {
	margin: 4px;
}

.infoContainer {
	border: 1px solid;
	border-radius: 4px;
	background: white;
	display: flex;
	flex-direction: column;
	margin: 15px 0;
}

.infoContainer > span:not(:last-child) {
	border-bottom: 1px solid;
}

.infoContainer > span {
	padding: 5px 10px;
	font-size: 1.2em;
}

.deviceImage svg {
	border-radius: 1em;
}

.deviceImage text {
	pointer-events: none;
	font-family: unset;
}

.panelContainer fieldset {
	border: none;
	padding: 0;
	position: relative;
	margin-top: 20px;
}

.panelContainer fieldset legend {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 2px 4px;
	position: absolute;
	color: black;
	top: -10px;
	left: 15px;
}

.panelContainer .checkbox label {
	margin-right: 10px;
}

.panelContainer .checkbox {
	display: flex;
	justify-content: space-between;
	background-color: white;
	color: black;
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 5px;
	position: relative;
}

.panelContainer .checkbox span {
	padding-left: 1px;
	height: 100%;
	top: 0;
	margin-left: -4px;
	position: absolute;
	background: black;
}

.tablesContainer > * {
	margin-bottom: 1em !important;
}

@media (max-width: 700px) {
	.headerButtons {
		flex-direction: column;
	}

	.panelContainer {
		padding: 6px;
	}

	.deviceSVG {
		margin: 6px 0;
		width: 80vw;
	}
}

.deviceContainer {
	display: block;
	box-sizing: border-box;
	overflow: auto;
	max-width: 100%;
}
