.buttonContainer {
	display: flex;
	flex-direction: column;
	margin: auto;
	max-width: 18em;
	margin-top: 36px;
	color: #505050;
}

.buttonContainer > button {
	font-size: 1.5em;
	margin: 15px 0;
}
