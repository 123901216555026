.button {
	cursor: pointer;
	border-radius: 1em;
	color: var(--font-color);
	position: relative;
	background: white;
	padding: 10px 15px;
	font-size: 0.9em;
	transition: 0.5s;
	border: none;
	box-shadow: 3px 3px 5px #bdbdbd;
	user-select: none;
}

.button:hover {
	box-shadow: 3px 3px 5px 2px #bdbdbd;
}

.button:active,
.button[name="active"] {
	background: var(--success-color) !important;
}

.refresh {
	background-color: #ffe07b !important;
	animation: pulse 2s infinite;
}

.error {
	color: black !important;
	background-color: var(--error-color) !important;
}

.loading {
	color: black;
	background-color: #ffe07b !important;
	animation: pulse 2s infinite;
}
.loading::before {
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
}

.button:disabled {
	color: white;
	background-color: gray !important;
}

.notification {
	padding: 4px;
	box-shadow: 3px 3px 5px #bdbdbd;
	pointer-events: none;
	border-radius: 10px;
	background: white;
	transition: 1s;
	opacity: 0;
	position: absolute;
	right: 100px;
	top: -10px;
}

.notification[name="show"] {
	opacity: 1;
}
