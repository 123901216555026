.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

.container h1,
.container h2 {
	margin: 20px;
	color: white;
}

.container button {
	margin: 20px 0;
	font-size: 1.5em;
}

.background {
	width: 100vw;
	height: 100vh;
	z-index: -1;
	position: fixed;
	background: #b8cd30;
}

.background > svg {
	color: #a7b92c;
	position: absolute;
	filter: blur(4px);
}

.background > svg[name="top-left"] {
	transform: rotate(30deg);
	top: -4em;
	right: -4em;
	height: 15em;
}

.background > svg[name="bottom-right"] {
	transform: rotate(30deg);
	bottom: 4em;
	right: -4em;
	height: 10em;
}

.background > svg[name="bottom-left"] {
	transform: rotate(20deg);
	bottom: -4em;
	left: -4em;
	height: 15em;
}

.background > svg[name="center-1"] {
	transform: rotate(20deg);
	bottom: 8em;
	right: 20em;
	height: 25em;
}

.background > svg[name="center-2"] {
	transform: rotate(20deg);
	top: -3em;
	left: -4em;
	height: 20em;
}

.background > svg[name="center-3"] {
	transform: rotate(20deg);
	top: 13em;
	left: 24em;
	height: 15em;
}
