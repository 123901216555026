.main {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
}

.header {
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.header > div > button {
	margin-left: 15px;
}

.panelContainer {
	color: white;
	border-radius: 1em;
	margin-bottom: 2em;
	padding-bottom: 1em;
	backdrop-filter: blur(8px);
	background-color: #00000030;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	width: 100%;
}

.table .icon {
	height: 0;
	padding: 0;
	text-align: center;
	position: relative;
}

.table .icon button {
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
}
