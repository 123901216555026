.titleContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	pointer-events: none;
}

.titleContainer > h1 {
	margin-top: 0;
}

.container {
	z-index: 1;
	height: 90vh;
	position: relative;
	display: flex;
	width: 100%;
	justify-content: center;
	flex-direction: column;
}

.mainContainer {
	height: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	font-size: 14px;
}

.productsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.products {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow-y: scroll;
}

.categoriesPage {
	margin-top: 7rem;
}

.cartButton {
	border: none;
	cursor: pointer;
	background: transparent;
	position: relative;
}
.cartButton:hover {
	box-shadow: none;
}

.searchInput {
	min-width: 30em;
	margin: 20px 0;
	width: 35%;
	background: #e6e6e6 url("assets/desktop/ProductsPage/search.svg") no-repeat 1.5em center;
	background-size: 1.5em;
	color: #000;
	box-shadow: unset;
	padding: 0.7em 3em;
	font-weight: 600;
	text-align: center;
	font-size: 1.5em;
}

.searchInput:focus {
	outline: none;
	border-color: #929292;
}

.buttonsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.changeDateButton {
	margin-top: 16px;
	background: var(--primary);
	white-space: nowrap;
	color: #fff;
	font-weight: 600;
	font-size: 1.1em;
}

.productContainer {
	background: #ededed;
	margin: 8px 24px;
	border-radius: 4px;
	display: flex;
	position: relative;
	align-items: stretch;
	justify-content: space-between;
}

.productContainer > img {
	height: 8rem;
	max-width: 300px;
	aspect-ratio: 4 / 5;
	object-fit: cover;
	border-radius: 4px;
	margin: 16px;
	user-select: none;
}

.productInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 80%;
	user-select: none;
}

.productTitle {
	margin-bottom: 12px;
}

.productAvailabilityContainer {
	background: #f39200;
	color: #fff;
	border-radius: 4px;
	padding: 16px;
	font-size: 1em;
	font-weight: 500;
	text-align: right;
	white-space: nowrap;
	height: fit-content;
	user-select: none;
}

.productCartControls {
	width: fit-content;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background: #fff;
}

.productAddToCartContainer {
	position: absolute;
	bottom: 0px;
	right: 0px;
}

.productInKioskContainer {
	background: var(--primary);
	color: #fff;
	border-radius: 4px;
	padding: 2px 4px;
	font-size: 1em;
	font-weight: 500;
	text-align: right;
}

.cartIconAmount {
	position: absolute;
	background-color: #f39200;
	color: #fff;
	box-sizing: border-box;
	height: 2em;
	width: 2em;
	border-radius: 1em;
	font-size: 0.9em;
	text-align: center;
	font-weight: 700;
	line-height: 2em;
	z-index: 1;
	top: 2em;
	left: 0.3em;
}
