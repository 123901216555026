.container {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 4px;
	max-width: 500px;
	height: fit-content;
	max-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 1px 5px black;
}

.container::before {
	top: 0;
	content: "";
	position: absolute;
	background-color: #00000030;
	backdrop-filter: blur(8px);
	height: 100%;
	width: 100%;
	z-index: -1;
}

.header {
	color: white;
	cursor: move;
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: 4px;
	backdrop-filter: blur(8px);
	background-color: var(--primary);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.486);
	justify-content: space-around;
}

.mainContainer {
	height: 80vh;
	position: relative;
	width: 100%;
}

.formContainer {
	width: 100%;
	margin-bottom: 15px;
	overflow-y: auto;
	overflow-x: hidden;
}

.form {
	margin: auto;
	margin-bottom: 15px;
	padding-top: 20px;
	height: calc(100% - 100px);
	width: calc(100% - 2em);
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	justify-content: space-between;
}

.form fieldset {
	border: none;
	padding: 0;
	position: relative;
}

.form fieldset legend {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 2px 4px;
	position: absolute;
	color: black;
	top: -10px;
	left: 15px;
}

.form textarea {
	resize: vertical;
	width: calc(100% - 4.2em - 15px);
	font-family: inherit;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

.buttons > button {
	margin-left: 15px;
}

.form input[type="text"],
.form input[type="number"] {
	width: calc(100% - 4.2em - 15px);
}

.formContainer > * {
	width: 100%;
	margin: 15px 0;
}

.mapContainer {
	min-height: 300px;
	height: 40vh;
}

.checkbox label {
	margin-right: 10px;
}

.checkbox {
	background-color: white;
	width: fit-content;
	color: black;
	background-color: white;
	border: 1px solid black !important;
	border-radius: 4px;
	padding: 5px !important;
	position: relative;
}

.checkbox input {
	box-shadow: none;
}

.checkbox span {
	padding-left: 1px;
	height: 100%;
	top: 0;
	margin-left: -4px;
	position: absolute;
	background: black;
}

.checkboxContainer {
	display: flex;
	width: 80%;
	justify-content: space-around;
}

.select {
	padding: 1.1em 2em;
	border-radius: 1em;
	width: calc(100% - 15px);
	font-size: 1em;
}

.selectWeekDaysContainer {
	box-shadow: 3px 3px 5px #bdbdbd;
	display: flex;
	justify-content: space-evenly;
	background: #fff;
	border-radius: 1em;
	max-width: calc(100% - 15px);
}

.selectWeekDaysContainer > div {
	transition: 0.5s;
	text-align: center;
	padding: 1.1em 0;
	width: 100%;
}

.selectWeekDaysContainer > div:first-child {
	border-radius: 1em 0 0 1em;
}

.selectWeekDaysContainer > div:last-child {
	border-radius: 0 1em 1em 0;
}

.selectWeekDaysContainer > div:hover {
	background: #e3ffc2;
}

.selectWeekDaysContainer > div.active {
	background: var(--primary);
}

.selectWeekDaysContainer > span {
	border-right: 1px solid black;
}
