.navItem {
    font-size: 2em;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}
.navItem_bolded {
    font-weight: 700;
}
.navItem:hover::after{
    transform: scale(1);
}
.navItem::after{
    transition: transform 0.2s ease-in-out;
    transform: scale(0); 
    content: "";
    display: block;
    width: 100%;
    height: 0.2em;
    bottom: -.1em;
    left:0;
    position: absolute;
    background-color:var(--primary);
    clip-path: polygon(0 50%, 60% 100%, 100% 60%, 75% 0);
}